<template>
  <v-dialog
      v-model="dialog"
      max-width="800"
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>{{ title }} {{ type }}</span>
          <hr/>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
<!--            <v-col cols="12" md="12">-->
<!--                <label>Title</label>-->
<!--              <v-radio-group-->
<!--                  v-model="user.title"-->
<!--                  :error="$v.user.title.$error"-->
<!--                  hide-details-->
<!--                  row-->
<!--              >-->
<!--                <v-radio-->
<!--                    class="mx-5"-->
<!--                    label="Mr"-->
<!--                    value="mr"-->
<!--                ></v-radio>-->
<!--                <v-radio-->
<!--                    class="mx-5"-->
<!--                    label="Mrs"-->
<!--                    value="mrs"-->
<!--                ></v-radio>-->
<!--              </v-radio-group>-->
<!--              <span class="text-danger" v-if="$v.user.gender.$error">**Please Select Title</span>-->
<!--            </v-col>-->
            <v-col cols="12" md="6">
              <v-text-field
                  :error="$v.user.first_name.$error"
                  v-model="user.first_name"
                  hide-details
                  outlined
                  dense
              >
                <template v-slot:label>
                  First name <span class="text-danger">*</span>
                </template>
              </v-text-field
              >
              <span class="text-danger" v-if="$v.user.first_name.$error"
              >**This First Name Field is Required</span
              >
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                  label="Middle name"
                  v-model="user.middle_name"
                  hide-details
                  outlined
                  dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                  v-model="user.last_name"
                  :error="$v.user.last_name.$error"
                  hide-details
                  outlined
                  dense
              >
                <template v-slot:label>
                  Last name <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.user.last_name.$error"
              >**This Last Name Field is Required</span
              >
            </v-col>

            <v-col cols="12" md="6">
              <v-file-input
                  v-model="user.file"
                  @change="previewImage"
                  prepend-icon=""
                  prepend-inner-icon="mdi-file"
                  hide-details
                  outlined
                  dense
              >
                <template v-slot:label>
                  Profile image
                </template>
              </v-file-input>
            </v-col>

            <v-col cols="12" md="6" v-if="imgUrl">
              <v-img
                  :lazy-src="imgUrl"
                  max-height="150"
                  max-width="150"
                  :src="imgUrl"
              ></v-img>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field :readonly="user.id?true:false"
                            v-model="user.email"
                            @blur="checkDuplicateEmail"
                            :error="$v.user.email.$error"
                            hide-details
                            outlined
                            dense
              >
                <template v-slot:label>
                  Email <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.user.email.$error"
              >**This Email Field is Required</span
              >
              <span class="text-danger" v-if="!$v.user.email.email"
              >**Email must be valid</span
              >
              <span class="text-danger" v-if="!buttonDisabled"
              >This Email Already Exists ! Try Again</span
              >
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                  label="Phone"
                  v-model="user.phone"
                  hide-details
                  outlined
                  dense
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                  v-model="user.mobile"
                  :error="$v.user.mobile.$error"
                  hide-details
                  outlined
                  dense
              >
                <template v-slot:label>
                  Mobile <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.user.mobile.$error"
              >**This Mobile Field is Required</span
              >
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                  label="Work phone"
                  v-model="user.work_phone"
                  hide-details
                  outlined
                  dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" v-if="type != 'agent'">
              <v-menu
                  ref="menuDate"
                  v-model="menuDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="user.date_of_birth"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                  >
                    <template v-slot:label>
                      Date of birth <span class="text-danger"></span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                    v-model="user.date_of_birth"
                    :max="max_date_of_birth"
                    outlined
                    dense
                    no-title
                    @input="menuDate = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                  outlined
                  dense
                  :items="counselors"
                  item-value="id"
                  item-text="full_name"
                  v-model="user.admin_user_id"
                  label="Managing counselor(team)"
              >
              </v-select>
            </v-col>

            <v-col cols="12" md="6" v-if="currentUser && currentUser.access_type == 'head'">
              <v-select
                  outlined
                  dense
                  :items="ourBranches"
                  item-value="id"
                  item-text="name"
                  v-model="user.branch_id"
                  label="Managing branch"
                  @change="onChangeBranch"
              >
              </v-select>
            </v-col>

            <v-col cols="12" v-if="user.is_student">
              <v-autocomplete
                  outlined
                  :search-input.sync="searchAgent"
                  :error="$v.user.added_by.$error"
                  v-model="user.added_by"
                  dense
                  placeholder="Type to search agent"
                  :items="agents"
                  item-text="full_name"
                  item-value="id"
                  small-chips
                  deletable-chips
              >
                <template v-slot:label>
                  Select Agent <span class="text-danger">*</span>
                </template>
              </v-autocomplete>
              <span class="text-danger" v-if="$v.user.added_by.$error">This Field is required</span>
            </v-col>
<!--            <v-col cols="12" md="12" v-if="!edit">-->
<!--              <v-checkbox v-model="user.auto_generate">-->
<!--                <template v-slot:label>-->
<!--                  <span class="text-left font-weight-medium">-->
<!--                    Automatically Create a New Password-->
<!--                  </span>-->
<!--                </template>-->
<!--              </v-checkbox>-->
<!--            </v-col>-->
            <v-col cols="12" md="12" v-if="!user.auto_generate && !edit">
              <v-text-field
                  label="Password"
                  :error="$v.user.password.$error"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                  v-model="user.password"
                  outlined
                  dense
              >
                <template v-slot:label>Password <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.user.password.$error"
              >**This Password Field is Required</span
              >
            </v-col>

            <v-col cols="12" v-if="type === 'agent'">
              <v-text-field v-model="user.business_name" label="Business name"
                            outlined dense
                            :error="$v.user.business_name.$error"
              ></v-text-field>
              <span class="text-danger" v-if="$v.user.business_name.$error"
              >**This business field is Required</span>
            </v-col>

            <v-col cols="12" md="4" v-if="user.is_student && !edit">
              <v-checkbox
                  v-model="user.send_email"
                  label="Notify Student"
                  hide-details
              ></v-checkbox>
            </v-col>

            <v-col cols="12" md="6">
              <v-radio-group
                  v-model="user.gender"
                  :error="$v.user.gender.$error"
                  row
              >
                <span class="mt-1">Select gender</span> <span class="text-danger">*</span>
                <v-radio
                    label="Male"
                    value="male"
                ></v-radio>
                <v-radio
                    label="Female"
                    value="female"
                ></v-radio>
              </v-radio-group>
              <span class="text-danger" v-if="$v.user.gender.$error">**Please Select Gender</span>
            </v-col>

            <v-col cols="12" md="3" v-if="type === 'agent'">
              <v-switch
                  v-model="user.is_agent"
                  label="Agent"
                  hide-details
              ></v-switch>
            </v-col>

            <v-col cols="12" md="3" v-if="type === 'student'">
              <v-switch
                  v-model="user.is_student"
                  label="Candidate"
                  hide-details
              ></v-switch>
            </v-col>

            <v-col cols="12" md="3">
              <v-switch
                  v-model="user.is_active"
                  label="Status"
                  hide-details
              ></v-switch>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
          <v-btn color="mr-2" text dark medium @click="closeDialog">
              Cancel
          </v-btn>
          <v-btn
              v-if="checkIsAccessible('user', 'create') || checkIsAccessible('user', 'edit')"
             class="btn btn-primary"
             :disabled="!buttonDisabled"
             @click="createOrUpdate()"
             :loading="loading"
          >
            Save
          </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {email, required, requiredIf} from "vuelidate/lib/validators";
import UserService from "@/services/user/UserService";
import UserApplicantManagerService from "@/services/user/applicant-manager/UserApplicantManagerService";

const user = new UserService();
const userApplicationManager = new UserApplicantManagerService();

export default {
  name: "CreateAndUpdate",
  data() {
    return {
      title: '',
      dialog: false,
      loading: false,
      edit: false,
      menuDate: false,
      type: null,
      activePicker: null,
      errors: [],
      counselors: [],
      agents: [],
      buttonDisabled: true,
      showPassword: false,
      changeBranch: 0,
      imgUrl: null,
      searchAgent: '',
      user: {
        title: 'mr',
        first_name: '',
        middle_name: '',
        last_name: '',
        file: null,
        role: '',
        phone: '',
        mobile: '',
        work_phone: '',
        gender: 'male',
        date_of_birth: '',
        is_student: false,
        is_agent: false,
        is_active: true,
        auto_generate: false,
        agent_application_status: "approved",
        password: '',
        admin_user_id: '',
        business_name: '',
        added_by: '',
      },
    }
  },

  validations: {
    user: {
      first_name: {required},
      last_name: {required},
      mobile: {required},
      gender: {required},
      added_by: {
        required:
            requiredIf(function () {
              return !this.edit && this.user.is_student
            })
      },
      business_name: {
        required:
            requiredIf(function () {
              return this.type == 'agent' ? true : false;
            })
      },
      email: {required, email},
      password: {
        required: requiredIf(function () {
          return this.user.auto_generate == false;
        })
      },
    }
  },

  computed: {
    max_date_of_birth() {
      let dob = this.$moment(new Date()).subtract('years', (15)).format("YYYY-MM-DD")
      this.user.date_of_birth = dob
      return dob;
    }
  },
  watch: {
    menuDate(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    searchAgent(val){
      user
          .getAgents(val)
          .then((response) => {
            this.agents = response.data.agent;
          })
          .catch(() => {});
    }
  },

  mounted() {
    this.getApplicantManagers();
    this.getAllBranches();
  },

  methods: {
    onChangeBranch() {
        this.changeBranch = 1;
    },
    getApplicantManagers(){
      this.loading = true;
      userApplicationManager
          .getAll()
          .then((response) => {
         this.counselors = response.data.application_managers
          })
          .catch((err) => {
            this.loading = false;
          })
          .finally(() => {
            this.loading = false;
          });
    },

    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
      this.$v.$reset()
    },

    createUser(type) {
      this.dialog = true;
      this.edit = false;
      this.title = 'Add';
      if (type == 'agent') {
        this.type = type;
        this.user.is_agent = true;
        this.user.is_student = false;
      } else {
        this.type = type;
        this.user.is_student = true;
        this.user.is_agent = false;
      }
    },

    editUser(type, item) {
      this.dialog = true;
      this.edit = true;
      this.title = 'Edit';
      this.user = item;
      if (this.user.image_path != null) {
        this.imgUrl = this.user.image_path.thumb;
      }
      if (type == 'agent') {
        this.type = type;
      } else {
        this.type = type;
      }
    },

    convertToFormData() {
      let formData = new FormData();
      if(this.changeBranch) {
        formData.append('update-branch', 1);
      }
      for (let key in this.user) {
        if (key === "file" && this.user[key] != null && this.user[key] != undefined) {
          formData.append('file', this.user[key]);
        } else {
          if (this.user[key]) {
            formData.append(key, this.user[key]);
          }
        }
      }
      return formData;
    },

    previewImage() {
      if (this.user.file != null) {
        this.imgUrl = URL.createObjectURL(this.user.file)
      } else {
        this.imgUrl = null;
      }
    },

    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        if (!this.edit) this._create();
        else this.update();
      }
    },

    _create: function () {
      this.loading = true;
      let form = this.convertToFormData();
      user
          .create(form)
          .then(response => {
            this.$snotify.success(`User ${this.type} Created Successfully`);
            this.errors = [];
            this.user.auto_generate = false;
            this.loading = false;
            this.$emit('refresh');
            this.closeDialog();
            this.resetForm();
          })
          .catch(error => {
            this.loading = false;
          })
    },

    update: function () {
      this.loading = true;
      let form = this.convertToFormData();
      user
          .update(this.user.id, form)
          .then(response => {
            this.$snotify.success(`User ${this.type} Update Successfully`);
            this.errors = [];
            this.user.auto_generate = false;
            this.loading = false;
            this.$emit('refresh');
            this.closeDialog();
            this.resetForm();
          })
          .catch(error => {
            this.loading = false;
          })
    },

    checkDuplicateEmail() {
      if (this.edit) {
        user
            .checkDuplicateEmailForUpdate(this.user.id, this.user)
            .then(response => {
              if (!response.data) {
                this.buttonDisabled = response.data;
              } else {
                this.buttonDisabled = response.data;
              }
            })
            .catch(error => {
              this.errors = error.errors;
            })
      } else {
        user
            .checkDuplicateEmail(this.user)
            .then(response => {
              if (!response.data) {
                this.buttonDisabled = response.data;
              } else {
                this.buttonDisabled = response.data;
              }
            })
            .catch(error => {
              this.errors = error.errors;
            })
      }
    },

    resetForm() {
      this.showPassword = false;
      this.buttonDisabled = true;
      this.type = null;
      this.imgUrl = null;
      this.changeBranch = 0;
      this.user = {
        title: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        role: '',
        phone: '',
        mobile: '',
        work_phone: '',
        is_student: false,
        is_agent: false,
        is_active: true,
        auto_generate: false,
        agent_application_status: "approved",
        password: '',
      };
      // this.$emit('refresh');
    },
  },
}
</script>