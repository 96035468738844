<template>
  <v-dialog
    v-model="dialog"
    max-width="500"
    scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span> Change Password </span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="">
            <v-col cols="6">
              Auto Generate ?
              <v-switch
                  v-model="autoGenerate"
                  :label="autoGenerate ? 'Yes': 'No'"
                  @change="togglePasswordField"
              ></v-switch>
            </v-col>
            <v-col v-if="! autoGenerate" cols="12" md="12">
              <v-text-field
                label="Password *"
                outlined
                type="password"
                dense
                :error="$v.user.password.$error"
                v-model="user.password"
                :type="showPassword?'text':'password'"
                :append-icon="showPassword?'mdi-eye-off':'mdi-eye'"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
              <span class="text-danger" v-if="errors.password" >{{errors.password[0]}}</span>
              <span class="text-danger"  v-if="!$v.user.password.minLength">
                Password must have at least 8 characters.
              </span>
            </v-col>

            <v-col v-if="!autoGenerate" cols="12" md="12">
              <v-text-field
                label="Confirm Password *"
                outlined
                dense
                type="password"
                :error="$v.user.password_confirmation.$error"
                v-model="user.password_confirmation"
                :type="showConfirmPassword?'text':'password'"
                :append-icon="showConfirmPassword?'mdi-eye-off':'mdi-eye'"
                @click:append="showConfirmPassword = !showConfirmPassword"
              ></v-text-field>
              <span class="text-danger" v-if="errors.password_confirmation" >{{errors.password_confirmation[0]}}</span>
              <span
                class="text-danger"
                v-if="!$v.user.password_confirmation.sameAsPassword"
              >
                Password Must Match
              </span>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            text
            @click="closeDialog"
        >
          Cancel
        </v-btn>

        <v-btn
            class="btn btn-primary"
            @click="updatePassword()"
            :loading="loading"
        >
          Save
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { requiredIf, minLength, sameAs} from "vuelidate/lib/validators";
import UserService from "@/services/user/UserService";

const user = new UserService();

export default {
  name: "UserPasswordChange",
  data() {
    return {
        errors: [],
        dialog: false,
        loading: false,
        showPassword: false,
        showConfirmPassword: false,
        autoGenerate: 1,
        user: {
          password: '',
          password_confirmation : '',
        },
        userId: null,
    }
  },
  validations:{
    user : {
      password: {
        minLength: minLength(8),
        required: requiredIf(function (){
          return this.autoGenerate === 0;
        }),
      },
      password_confirmation: {sameAsPassword: sameAs('password')},
    }
  },

  methods: {
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
    },

    openDialog() {
      this.$v.$reset();
      this.dialog = true;
    },

    changePassword(item) {
        this.resetForm();
        this.openDialog();
        this.userId = item.id;
    },

    togglePasswordField() {
      this.user.password = null;
      this.user.password_confirmation = null;
    },

    updatePassword() {
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        this.loading = true;
        this.user['form_type'] = "update-password";
        if(this.autoGenerate){
          this.user.autoGenerate = 1;
        }else{
          this.user.autoGenerate = 0;
        }
        user
        .updatePassword(this.userId, this.user)
        .then(response => {
            if(response.data.status == "OK") {
              this.$snotify.success("Password changed successfully.");
              this.resetForm();
              this.closeDialog();
              this.loading = false;
              this.$emit('refresh');
            }
        })
        .catch((err) => {
            this.loading = false;
            this.errors = err.response.data.errors;
            this.$snotify.error("Oops something went wrong");
        });
      }
    },

    resetForm() {
      this.errors=[];
      this.$v.$reset();
      this.user = {
        password: '',
        password_confirmation : '',
      }
    }
  }
}
</script>
