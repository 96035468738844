import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class UserApplicantManagerService {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/user';
    }

    sort(userId, data = []) {
        let url = `${this.#api}/${userId}/applicant-manager/sort`;
        return apiService.post(url, data);
    }

    paginate(userId, data = {}, index = null) {
        let url = `${this.#api}/${userId}/applicant-manager`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    create(userId, data) {
        let url = `${this.#api}/${userId}/applicant-manager`;
        return apiService.post(url, data)
    }

    all(userId) {
        let url = `admin/applicant-manager/user/${userId}/get/all`
        return apiService.get(url)
    }

    getAll(userId) {
        let url = `admin/applicant-managers`
        return apiService.get(url)
    }

    getAdminUsers(userId) {
        let url = `admin/applicant-manager/user/${userId}/get/admin-users`
        return apiService.get(url)
    }

    show(userId, id) {
        let url = `${this.#api}/${userId}/applicant-manager/${id}`
        return apiService.get(url)
    }

    update(userId, id, data) {
        let url = `${this.#api}/${userId}/applicant-manager/${id}`
        return apiService.put(url, data)
    }

    delete(id) {
        let url = `admin/applicant-manager/${id}`
        return apiService.delete(url);
    }
}